@tailwind base;
@tailwind utilities;

:root {
    --mui-palette-Alert-successStandardBg: rgba(37, 157, 27, .5);
    --mui-palette-Alert-successIconColor: #259D1B;

    --mui-palette-Alert-errorStandardBg: rgba(222, 72, 72, .5);
    --mui-palette-Alert-errorIconColor: #DE4848;

    --mui-palette-Alert-warningStandardBg: rgba(233, 174, 48, .5);
    --mui-palette-Alert-warningFilledBg: #E9AE30;
    --mui-palette-Alert-warningIconColor: #E9AE30;
}

* {
    box-sizing: border-box;
}